<template>
  <div class="scroll-book">
    <div class="table">
      <van-search class="header" v-model="value" shape="round" show-action
        background="url(https://img.mmjp5000.com/images/2022/11/12/-52x.png) cover" placeholder="请输入搜索关键词"
        @search="handleSearch">
        <template #action>
          <div @click="handleSearch">搜索</div>
        </template>
      </van-search>
      <div class="content">
        <virtual-list ref="listRef" class="list-horizontal scroll-touch" :data-key="'id'" :data-sources="gridData"
          :data-component="itemComponent" :direction="'horizontal'" :wrap-class="'wrapper-scroll-sushi'"
          :item-class="'list-item-horizontal'" :offset="offset" :extra-props="{ treeId: [treeId, enterTreeId] }"
          @tobottom="toBottom" @totop="toTop" />
        <pu-info :puInfo="puInfo" style="width: 40px;height: 100%" />
      </div>
    </div>
    <div class="cover" :class="{ flip: flipCover }" @click="flipCover = true">
      <cover :disabled="true" v-bind="puInfo" />
    </div>
    <van-popup v-model="show">
      <div class="header">搜索结果</div>
      <table class="search-result">
        <tr class="tr">
          <th class="puming">姓名</th>
          <!-- <th class="puming">谱名</th> -->
          <!-- <th class="id">用户ID</th> -->
          <th class="paihang">派</th>
          <th class="shouji">父亲姓名</th>
          <!-- <th class="shouji">祖籍</th> -->
        </tr>
        <tr class="result" v-for="item in searchResult" :key="item.id" @click="jumpToTreeId(item.id)">
          <td class="puming">{{ item.username || '-' }}</td>
          <!-- <td class="puming">{{ item.jpmc || '-' }}</td> -->
          <!-- <td class="id">{{ item.id || '-' }}</td> -->
          <td class="paihang">{{ item.factions_text || '-' }}</td>
          <td class="shouji">{{ item.father_name || '-' }}</td>
          <!-- <td class="shouji">{{ item.ancestral || '-' }}</td> -->
        </tr>
      </table>
    </van-popup>
  </div>
</template>

<script>
import { Search, Popup } from 'vant'
import CoverVue from '@/components/Book/Cover.vue';
import PuInfoVue from '@/components/Book/PuInfo.vue';
import VirtualList from 'vue-virtual-scroll-list'
import SushiItemVue from '@/components/Book/SushiItem.vue';
import numberToChinese from "number-to-chinese-words";
import { chunk, cloneDeep } from "lodash";
import apis from '@/apis'

export default {
  name: 'BookScroll',
  components: {
    Cover: CoverVue,
    PuInfo: PuInfoVue,
    'van-search': Search,
    'van-popup': Popup,
    VirtualList
  },
  data() {
    return {
      value: '',
      flipCover: false,
      puInfo: {},
      creatId: '',
      treeId: '',
      enterTreeId: '',
      itemComponent: SushiItemVue,
      items: [],
      offset: 0,
      gridData: [],
      show: false,
      searchResult: [],
      page: {
        next: 1,
        prev: 1,
        current: 1
      },
      hasMore: {
        next: true,
        prev: true
      },
      loading: false,
      selectIndex: 0
    }
  },
  watch: {
    selectIndex: {
      handler(index) {
        this.$refs.listRef.scrollToIndex(index)
      }
    }
  },
  created() {
    this.creatId = this.$route.query.creatId; // 谱id
    this.enterTreeId = +this.$route.query.treeId || +this.$route.query.treeid; // 谱id
    this.getPuInfo()
    this.getDiaoxianData1({ treeId: this.enterTreeId }).then(({ nowPage }) => { // 首次进来，跳转到页面开头，最右侧
      this.page = {
        current: nowPage,
        prev: nowPage,
        next: nowPage
      }
      const index = this.gridData.findIndex(item => item.id === this.enterTreeId)
      this.$refs.listRef.scrollToIndex(index > 0 ? index - 1 : index)
      // this.selectIndex = this.gridData.length - 1
      // this.$refs.listRef.scrollToIndex(this.gridData.length - 1)
    })
  },
  mounted() {
    // setTimeout(() => {
    //   const element = document.querySelector('.wrapper-scroll-sushi')
    //   const width = element.offsetWidth
    //   this.offset = width
    // }, 1000);
  },
  methods: {
    handleSearch() {
      apis
        .searchUser({
          username: this.value,
          genealogyId: this.creatId,
        }).then(data => {
          console.log(data)
          this.searchResult = data
          this.show = true
        })
    },
    toBottom() {
      // 上一页
      console.log('to bottom')
      if (!this.hasMore.prev || this.page.prev === 1) return
      // this.page -= 1
      this.page.current = this.page.prev - 1
      this.getDiaoxianData1({ direction: 'prev' }).then(({ dataLength, nowPage }) => {
        // console.log(prevLength)
        this.page.prev = nowPage
        console.log(this.gridData.length, dataLength - 4)
        this.selectIndex = this.gridData.length - dataLength - 4
        // this.$refs.listRef.scrollToIndex(dataLength)
      })
      // this.flipCover = false
    },
    toTop() {
      // 下一页
      // const prevIndex = this.gridData.length - 1
      if (!this.hasMore.next) return
      this.page.current = this.page.next + 1
      this.getDiaoxianData1({ direction: 'next' }).then(({ prevLength, nowPage }) => {
        // console.log(prevLength)
        this.page.next = nowPage
        this.selectIndex = this.gridData.length - prevLength
        // this.$refs.listRef.scrollToIndex(this.gridData.length - prevLength)
      })
    },
    getPuInfo() {
      if (!this.creatId) return
      apis.getPudata({ creatId: this.creatId }).then(data => {
        this.puInfo = {
          ...data,
          zhubian: data.zhubian || ' ',
          xpsj: data.xpsj || ' '
        }
      })
    },
    jumpToTreeId(treeId) {
      console.log(treeId, this.$route)
      this.treeId = treeId
      this.hasMore = {
        next: true,
        prev: true
      }
      this.page = {
        prev: 1,
        current: 1,
        next: 1
      }
      // this.gridData = []
      this.getDiaoxianData1({ treeId }).then(({ nowPage, dataLength }) => {
        this.page = {
          current: nowPage,
          prev: nowPage,
          next: nowPage
        }
        const index = this.gridData.findIndex(item => item.id === treeId)
        this.$refs.listRef.scrollToIndex(index > 0 ? index - 1 : index)
        console.log(dataLength)
      })
      this.show = false
    },
    async getDiaoxianData1({ treeId, direction } = { treeId: '', direction: 'next' }) {
      if (this.loading) return
      this.loading = true
      const res = await apis.scrollListData({
        treeid: treeId,
        genealogyid: this.creatId,
        page: this.page.current,
      });
      const { data, count, nowPage } = res;
      // if (this.page !== nowPage) {
      //   this.page = +nowPage
      // }
      if (data.length === 0) {
        this.hasMore[direction] = false
      }
      const gridData = data.reduce((prev, item) => {
        prev = prev.concat(item);
        // console.log(descLength)
        // prev = prev.concat(item);
        if (item.spouseArrs?.length) {
          // 如果有配偶
          item.spouseArrs.forEach((itemSpouse) => {
            prev = prev.concat(itemSpouse);
          });
        }

        return prev;
      }, []);
      const prevLength = this.gridData.length
      if (treeId) {
        this.gridData = [...gridData.reverse()]
      } else {
        if (direction === 'next') {
          this.gridData = [...gridData.reverse(), ...this.gridData]
        } else {
          this.gridData = [...this.gridData, ...gridData.reverse()]
        }
      }

      // setTimeout(() => {
      //   // console.log(gridData.length)
      //   // const gridWidth = document.querySelector('.grid').clientWidth
      //   // this.offset = gridData.length * gridWidth
      //   const element = document.querySelector('.wrapper-scroll-sushi')
      //   const width = element.offsetWidth
      //   this.offset = width - this.lastWidth
      //   this.lastWidth = width
      // }, 0)
      this.loading = false
      return { prevLength, dataLength: gridData.length, nowPage: +nowPage }
    },
  }
}
</script>

<style lang="scss" scoped>
.scroll-book {
  position: relative;
  max-width: 100vw;
  height: 100vh;
  perspective: 1000px;
  overflow: hidden;

  .table {
    width: 750px;
    height: 1010px;

    .header {
      height: 115px;
      width: 750px;
      background-image: url(https://img.mmjp5000.com/images/2022/11/12/-52x.png);
      background-size: cover;
    }
  }

  .cover {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: aliceblue;
    transform-style: preserve-3d;
    transform-origin: right center;
    transition: all 0.5s ease-in;

    &.flip {
      transform: rotateY(180deg);
    }
  }

  .content {
    max-width: 750px;
    height: 1010px;
    border: 6px solid #000000;
    box-sizing: border-box;
    margin-top: 13px;
    position: relative;
    display: flex;
    padding: 15px;
    float: right;
  }

  .list-horizontal {
    height: 100%;
    width: calc(100% - 40px);
    border: 3px solid #000000;
    border-right: none;
    // justify-content: flex-end;
  }
}

.header {
  width: 639px;
  height: 58px;
  background: #F4F4F4;
  border-radius: 6px;
  line-height: 58px;

  font-size: 27px;
  font-weight: 400;
  color: #444444;
  padding: 0 20px;
  box-sizing: border-box;
}

.search-result {
  width: 640px;
  max-height: 400px;

  th,
  td {
    text-align: center;
    height: 70px;
    border-bottom: 1px solid #EBEBEB;
    ;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
  }

  td {
    &:last-of-type {
      border-bottom: none;
    }
  }
}
</style>

<style lang="scss">
.list-horizontal {
  width: 100%;
  border: 2px solid;
  border-radius: 3px;
  overflow-x: auto;
  border-color: dimgray;
  display: flex; // when using scrollToBottom()

  .list-item-horizontal {
    border-right: 1px solid #dfdfdf;
    // flex: auto;
    // width: fit-content;
    // overflow: hidden;
    // position: relative;
  }
}

.van-search__content {
  background-color: #fff;
}

.wrapper-scroll-sushi {
  display: flex;
  /* flex-direction: row-reverse; */
}
</style>