<template>
  <div ref="grid" class="grid" :class="{ 'is-active': treeId.includes(source.id) }" :style="{ width: width }">
    <div class="faction inner">
      <span dir="rtl">{{ reverseText(source.factions_text) }}</span>
    </div>
    <div class="sign inner">
      <span>{{ getSign(source) }}</span>
    </div>
    <div class="name inner">
      <span>{{ source.is_zeng === 1 ? source.name : source.username }}</span>
    </div>
    <div class="desc inner" ref="desc">
      <span>{{ source.des }}</span>
    </div>
  </div>
</template>

<script>
import numberToChinese from "number-to-chinese-words";

export default {
  name: 'SushiItem',
  props: {
    source: {
      type: Object,
      default: () => ({
        
      })
    },
    treeId: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      width: 'auto'
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.width = `${this.$refs.desc.clientWidth}px`
      // this.$forceUpdate()
    })
  },
  methods: {
    reverseText(text = '') {
      return text.split('').reverse().join('')
    },
    getSign(item) {
      if (item.sign) return item.sign;
      if (!item.id) return "";
      if (item.is_zeng === 1) {
        const sex = item.sex === 1 ? "子" : "女";
        if (!item.rank_id || item.isOnly)
          return item.father_id ? `${item.father_name}\n之${sex}` : "";
        // 直系
        const rank =
          item.rank_id === 1
            ? "长"
            : item.rank_id === 2
              ? "次"
              : numberToChinese.toWords(item.rank_id);
        return item.father_name ? `${item.father_name}\n${rank}${sex}` : "";
      } else {
        return item.sex === 1 ? "适" : item.is_live ? "配" : "妣";
      }
    },
    splitText(str) {
      if (!str) return "";
      let strArr = str.split("");
      let offset = 0;
      while (offset < str.length) {
        offset += 2;
        strArr.splice(offset + Math.floor(offset / 2) - 1, 0, "\n");
      }
      return strArr.join("");
    },
  },
}
</script>

<style lang="scss" scoped>
.grid {
  background-color: #fff;
  min-width: 133px;
  width: fit-content;
  height: 100%;
  border-left: 3px solid #000;
  color: #333333;
  // display: flex;
  // flex-flow: column nowrap;
  // flex: none;

  &.is-active {
    background-color: #D9D919;
  }

  &:last-of-type {
    // border-left: none;
  }

  .inner {
    border-bottom: 3px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: none;
  }

  .faction {
    height: 67px;
    font-size: 20px;
    text-align: left;
    white-space: nowrap;
    unicode-bidi: bidi-override;
    direction: rtl;
  }

  .sign {
    height: 80px;
    font-size: 20px;
    writing-mode: vertical-rl;
    // display: inline-block;
    text-align: center;
    white-space: pre;
    line-height: 1;
    // line-height: 80px;
  }

  .name {
    height: 118px;
    font-size: 32px;
    font-weight: 600;
    writing-mode: vertical-rl;
  }

  .desc {
    height: calc(100% - 118px - 80px - 77px);
    border-bottom: none;
    writing-mode: vertical-rl;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
    font-size: 20px;
    justify-content: start;
    flex: 1;
    width: auto;
    min-width: 100%;
    // box-sizing: border-box;
  }
}
</style>